import type { Highlight, Material, ProcessingType, Tool } from '~/types/base'

// @todo: translate!
const toolList: Array<Tool> = [
  {
    title: 'Wendeplatten-Werkzeuge',
    name: 'indexable-tools',
    teaser:
      'Unsere Wendeplatten-Werkzeuge bieten Ihnen eine hohe Leistung und Flexibilität in der Fertigung.',
    text: 'config.sectors.toolmaking.text',
    route: '#',
    image: {
      source: '/img/elements/tools/placeholder-indexable-tools.webp',
      alt: '',
    },
    productGroup: [
      {
        title: 'Planfräser',
        name: 'planfraese',
        productList: [
          //planworx
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Planfräser',
            productName: 'Planworx®',
            slug: 'planworx',
            subheadline:
              'Hochwirtschaftlich mit großer Spantiefe und überragender Laufruhe',
            vimeoId: '901813779',
            processingTypes: [
              'fasenfraesen',
              'planfraesen',
              'eintauchen-zirkular',
            ],
            introText:
              'Der Planworx® ist das Universalgenie zum Planfräsen. Dank seines negativen Plattensitzes, der für eine hohe Stabilität sorgt, meistert dieser Fräser selbst anspruchsvollste Aufgaben. Durch die hoch positive Wendeplattengeometrie wird ein besonders weicher Schnitt erreicht. Dank des Einstellwinkels von κ = 45° kann der Planworx® auch zum Fasenfräsen eingesetzt werden.',
            productImage: '/img/elements/tools/planworx-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Artikel: 10 100 331 + 05 31 8096',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Stabile Grundform, durch negativen Plattensitz, durch hochpositive WSP-Form, sehr weich schneidend.',
                x: 72,
                y: 13,
                scale: 4,
                translateX: -80,
                translateY: 83,
              },
              {
                text: 'Einstellwinkel κ = 45°, somit auch zum Fasenfräsen geeignet.',
                x: 13,
                y: 20,
                scale: 3.5,
                translateX: 73,
                translateY: 60,
              },
              {
                text: 'Beste Oberflächenergebnisse beim Schruppen, dank Planschneide.',
                x: 31.5,
                y: 8.5,
                scale: 4.5,
                translateX: 40,
                translateY: 105,
              },
            ],
            featureList: [
              'Sehr stabile Grundform',
              'Acht nutzbare Schneidkanten',
              'Leichter Schnitt auf Grund hoch positiver Wendeschneidplatten&shy;geometrie',
              'Ungleichteilung für weniger Schwingungen',
              'Kühlmittelbohrungen bis Werkzeugdurchmesser D = 125 mm',
              'Hervorragende Laufruhe',
              'Einstellwinkel κ = 45°',
              'spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
            ],
          },
          //mirrorworx
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Planfräser',
            productName: 'Mirrorworx®',
            slug: 'mirrorworx',
            subheadline:
              'Fräsen statt schleifen – glatte Oberflächen bei höchster Wirtschaftlichkeit',
            vimeoId: '901813779',
            processingTypes: ['planfraesen'],
            introText:
              'Der Mirroworx® ist ein Planfräskonzept zur Erzeugung spiegelglatter Oberflächen. Dies macht in vielen Fällen sogar den Einsatz eines zeit- und kostenintensiven Schleifprozesses überflüssig. Die herausragende Performance des Mirroworx® zeigt sich besonders in der Erzeugung von Oberflächen mit einer gemittelten Rautiefe von Rz < 2,5 µm in kürzester Bearbeitungszeit.',
            productImage: '/img/elements/tools/mirrorworx-hires.webp',
            highlightList: [
              {
                text:
                  'Selbstdrehende Wendeschneidplatte<br>2 25 283 SG + 03 83 836\n' +
                  '2 52 384 + 04 84 835',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Planschneide mit 3 Schneidkanten für spiegelglatte Oberflächen.',
                x: 72,
                y: 10.25,
                scale: 4,
                translateX: -60,
                translateY: 73,
              },
              {
                text: 'Schraube zum Einstellen des Planlaufes im µ-Bereich (bei zweischneidigen Aufsteckfräsern).',
                x: 33,
                y: 21,
                scale: 3.5,
                translateX: 36,
                translateY: 60,
              },
            ],
            featureList: [
              'Gemittelte Rautiefen von Rz < 2,5 µm',
              'Schleifprozess kann in einer Vielzahl der Fälle ersetzt werden',
              'Besondere Laufruhe',
              'Feinjustierung zum Einstellen des Planlaufs am Werkzeugvoreinstellgerät (Mirroworx® M)',
              'Auch für labile Bauteile geeignet',
              'Drei nutzbare Schneidkanten',
              'spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Eck-, Nut- und Fasfräser',
        name: 'eck-nut-fasfraeser',
        productList: [
          {
            title: 'Slotworx (Kappa 90°)',
            name: 'slotworx-kappa-90',
          },
          // slotworx m/l
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Eck-, Nut- und Fasfräser',
            productName: 'Slotworx® M und L',
            slug: 'slotworx-m-l',
            subheadline:
              'Mit hochmoderner Schneidengeometrie für universelle Anwendungen',
            vimeoId: '901813779',
            processingTypes: [
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
            ],
            introText:
              'Die sehr hohe Flexibilität im Produktportfolio macht den Slotworx® zur ersten Wahl im Bereich des Eck- und Nutfräsens. Durch die vielfältige Auswahl an Substraten, Beschichtungen sowie Geometrien wird eine Großzahl an Anwendungen abgedeckt. Im Bereich der Eckradien umfasst das Portfolio einen Bereich von r = 0,4 mm bis r = 5 mm. Zusätzlich kann der Slotworx® M durch den Einsatz der HF-Wendeschneidplatten zu einem High-Feed-Fräser umgerüstet werden.',
            productImage: '/img/elements/tools/slotworx-m-l-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>L: 5 52 368 + 05 68 820 (Platte Silber)<br>M HF: 4 25 267 + 04 67 848 HF',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Planschneide mit integrierter Planfase, für besonders gute Oberflächen.',
                x: 29,
                y: 13.5,
                scale: 4,
                translateX: 43,
                translateY: 59,
              },
              {
                text: 'Vibrationsarmes Fräsen von 90° Schultern, auch in tiefen Kavitäten.',
                x: 64,
                y: 24,
                scale: 3,
                translateX: -30,
                translateY: 37,
              },
              {
                text: 'Eingebetteter Plattensitz, für besondere Stabilität der WSP.',
                x: 40,
                y: 22,
                scale: 4,
                translateX: 27,
                translateY: 53,
              },
            ],
            featureList: [
              'Universelle Einsatzmöglichkeiten von Schruppen bis hin zum Schlichten verschiedener Materialien',
              'Optimierte Kühlmittelzufuhr bis an die Schneide',
              'Integrierte Schlichtfase ermöglicht hervorragende Oberflächenqualitäten',
              'Vielfältige Auswahl der Schneidplatten mit Eckradien von r = 0,4 mm bis r = 5 mm',
              'spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
              '<b>HF-Platte, Größe M:</b><br>High-Feed Wendeplatten für weiche und harte Werkstoffe bis über 60 HRC',
              '<b>HF-Platte, Größe M:</b><br>Extrem lange Standzeit und Laufruhe durch angepasste Spanflächengeometrie',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
          // slotworx vf
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Eck-, Nut- und Fasfräser',
            productName: 'Slotworx® VF',
            slug: 'slotworx-vf',
            subheadline:
              'Der Spezialist zum Schlichten in allen (un)möglichen Herausforderungen',
            vimeoId: '901813779',
            processingTypes: [
              'eckfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'planfraesen',
              'taschenfraesen',
            ],
            introText:
              'Der Slotworx® VF ist optimal für das Schlichten von 90°-Wänden und Planflächen geeignet und das sogar bis zu einer Bearbeitungstiefe von 10xD. Mit Radien von r = 0,8 mm und r = 2 mm ist der Slotworx® VF vielseitig einsetzbar.',
            productImage: '/img/elements/tools/slotworx-vf-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>VF09-025-D16-04-R+  + VF09-8035-R08-LH-2',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Hochgenau geschliffene Planschneide (1,5mm) und 90° Schneide (3mm) für beste Oberflächenergebnisse.',
                x: 29,
                y: 9.5,
                scale: 2.5,
                translateX: 32,
                translateY: 47,
              },
              {
                text: 'Mit Radius r = 0,8 mm und r = 2 mm.',
                x: 65,
                y: 5,
                scale: 3,
                translateX: -35,
                translateY: 68,
              },
              {
                text: 'Eingebetteter Plattensitz, für besondere Stabilität der WSP.',
                x: 45,
                y: 19,
                scale: 4,
                translateX: 8,
                translateY: 54,
              },
            ],
            featureList: [
              'Optimal für das Schlichten von 90°-Wand- oder Bodenflächen',
              'Größtmögliche Zähnezahl für hohen Vorschub',
              'Radius von r = 0,8 mm an der Wendeplatte reduziert den Schnittdruck',
              'Träger R+ sind für den Einsatz beider Wendeplatten r = 0,8 mm und r = 2 mm geeignet',
              'spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },

          {
            title: 'Quadworx',
            name: 'quadworx',
          },
          // squareworx
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Eck-, Nut- und Fasfräser',
            productName: 'Squareworx®',
            slug: 'squareworx',
            subheadline:
              'Verschiedene Bearbeitungsarten mit nur einer Schneidplatte und zwei Trägern',
            vimeoId: '901813779',
            processingTypes: [
              'eckfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'planfraesen',
              'taschenfraesen',
            ],
            introText:
              'Das Squareworx®-Werkzeugkonzept ist für zwei wesentliche Anwendungsfälle entwickelt worden: Zum einen als Eck-Nutfräser, zum Anderen als 45°-Fasfräser. Dadurch eignet sich das Werkzeug hervorragend für die Grob-, Mittel- und Feinzerspanung in vielfältigen Einsatzbereichen.',
            productImage: '/img/elements/tools/squareworx-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>SQ09-035-E16-05-45 + SQ09-8048-R08-MP',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Eine Wendeplatte, 2 Möglichkeiten: 1. Als Eck- Nutfräser, 2. als 45°-Fasfräser.',
                x: 29,
                y: 14,
                scale: 4,
                translateX: 35,
                translateY: 68,
              },
              {
                text: 'Eingebetteter Plattensitz, für eine besonders stabile Schneide und genaueste Positionierung.',
                x: 64,
                y: 14,
                scale: 3,
                translateX: -35,
                translateY: 68,
              },
            ],
            featureList: [
              'Werkzeug κ = 90°: Präzises Eck- / Nut- und Planfräsen, eingebettete Schneidplatten',
              'Werkzeug κ = 45°: Vorwärts und Rückwärts-Anfasen sowie Entgraten bis ap = 5 mm',
              'Vier Schneidkanten je Wendeschneidplatte',
              'Eckradius r = 0,8 mm',
              'Hohe Zähnezahl ermöglicht hohe Vorschübe',
              'Für Grob-, Mittel- und Feinzerspanung',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
            ],
          },
          {
            title: 'Uniworx Plus',
            name: 'uniworx-plus',
          },
        ],
      },
      {
        title: 'Kopierfräser',
        name: 'kopierfraeser',
        productList: [
          // spinworx
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Kopierfräser',
            productName: 'Spinworx®',
            slug: 'spinworx',
            subheadline:
              'Für mannarme Bearbeitung durch selbstdrehende Schneidplatten',
            vimeoId: '901813779',
            processingTypes: [
              'eckfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'planfraesen',
              'taschenfraesen',
            ],
            introText:
              'Spinworx®, das innovative Werkzeugsystem stellt in Frage, was jahrzehntelang nur so funktionierte: Die Schneidplatte muss „fest“ verschraubt werden – „lose“ hieße über kurz oder lang Plattenbruch! Nicht so beim Spinworx®! Die Platte dreht sich und macht händisches nachstellen überflüssig. Gleichzeitig wird über den gesamten Umfang der Platte eine 100%ige Nutzung der Schneidkante erreicht.',
            productImage: '/img/elements/tools/spinworx-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Selbstdrehende Wendeschneidplatte – die Platte nutzt ihre volle Schneidkante ohne per Hand gedreht zu werden und den Produktionsprozess zu pausieren.',
                x: 22,
                y: 12,
                scale: 4,
                translateX: 70,
                translateY: 77,
              },
              {
                text: 'Von hinten eingesetzter Stift – ermöglicht einen stabileren eingebetteten Plattensitz.',
                x: 41,
                y: 23,
                scale: 3.5,
                translateX: -50,
                translateY: 70,
              },
              {
                text: 'Optimierte Spanabfuhr - wirkt der Anhaftung von Spänen an der Platte entgegen und erleichtert die Spanabfuhr insbesondere bei Taschen und Nuten.',
                x: 74,
                y: 14,
                scale: 3,
                translateX: 10,
                translateY: 45,
              },
            ],
            featureList: [
              '100%ige Nutzung der kompletten Schneide',
              'Minimierung von Rüstzeiten, ein manuelles Weiterstellen der Platten entfällt',
              'Vielfach höhere Standzeiten und Spanvolumen ohne Stopp des Produktionsprozesses',
              'Optimal geeignet für die Schrupp- und Restmaterialbearbeitung',
              'Geringere Spanstauchung bedingt eine geringere Leistungsaufnahme und somit Schonung der Maschinenspindel',
              'Besonders langlebig trotz Reibung durch spezielle Legierung und über 80% recycelter Stahl für minimale Umweltbelastung',
              'Ermöglicht mannarme Bearbeitung durch selbstdrehende Schneidplatten',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
          // rundplattenfraeser
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Kopierfräser',
            productName: 'Rundplattenfräser K0-90°',
            slug: 'rundplattenfraeser',
            subheadline: 'Die Universalgenies für alle  Aufgaben',
            vimeoId: '901813779',
            processingTypes: [
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'eintauchen-vertikal',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
              'kopierfraesen',
            ],
            introText:
              'Die Rundplattenfräser von pokolm® gehören zu den am vielfältigsten einsetzbaren Werkzeugkonzepten. Standardmäßig sind diese in einem Durchmesserbereich zwischen 12 mm und 160 mm verfügbar sowie mit Wendeplattengrößen zwischen 7 mm und 20 mm. Die Werkzeuge sind mit einem Axialweinkel 0° und 7° erhältlich. Zusätzlich wurden die Werkzeugserie „VD“ entwickelt, die in herausfordernden Bereich bzgl. einer Schwingungsanfälligkeit Abhilfe schaffen.',
            productImage: '/img/elements/tools/rundplattenfraeser-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>80 300/7 HL + 04 16 8042 (Platten gold)',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Definierter Radius für konturgenaues Arbeiten.',
                x: 22,
                y: 12,
                scale: 3,
                translateX: 48,
                translateY: 50,
              },
              {
                text: 'Eingebetteter Plattensitz, für besonders hohe Stabilität der WSP.',
                x: 41.5,
                y: 22,
                scale: 4.5,
                translateX: 10,
                translateY: 45,
              },
              {
                text: 'Neutraler Plattensitz für konturgenaues Arbeiten oder 7° positiv für besonders weichen Schnitt.',
                x: 77,
                y: 10,
                scale: 3.5,
                translateX: -50,
                translateY: 70,
              },
            ],
            featureList: [
              'Werkzeugdurchmesser von 12 mm bis 160 mm',
              '0° Axialwinkel für höchste Konturgenauigkeit',
              '7° Axialwinkel sorgt für geringe Leistungsaufnahme',
              'Stabiler Plattensitz durch Wendeschneidplatten-Einbettung',
              'VD-Ausführungen verfügen über spezielle vibrationsdämpfende Eigenschaften',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Rhombusfräser',
        name: 'rhombusfraeser',
        productList: [
          // finworx
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Rhombusfräser',
            productName: 'Finworx®',
            slug: 'finworx',
            subheadline: 'Das Wirtschaftswunder mit 4 Schneiden',
            vimeoId: '901813779',
            processingTypes: [
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'eintauchen-vertikal',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
              'kopierfraesen',
              'fasenfraesen',
            ],
            introText:
              'Finworx® ist das Multitalent von Pokolm®. Das Schlichtwerkzeug eignet sich für eine Vielzahl von Fräsoperationen. Dank der verschiedenen Wendeschneidplatten kann eine breite Palette von Werkstoffen bearbeitet werden. Vier Schneidkanten machen das Werkzeugsystem höchst wirtschaftlich in seinem Einsatz.',
            productImage: '/img/elements/tools/finworx-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>4 25 285 SG + 03 85 836',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Eingebetteter Plattensitz, dadurch sehr stabile Grundform.',
                x: 32,
                y: 20,
                scale: 4,
                translateX: 42,
                translateY: 56,
              },
              {
                text: 'Radius r = 1 mm für konturgenaues Arbeiten.',
                x: 63,
                y: 10,
                scale: 3,
                translateX: -21,
                translateY: 51,
              },
            ],
            featureList: [
              'Schlichtwerkzeug für ein breites Anwendungsspektrum',
              'CBN und PKD-Schneidstoffe verfügbar',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'stainless-steel',
                main: false,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
          // xdhw/xdht
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Rhombusfräser',
            productName: 'XDHW | XDHT',
            slug: 'xdhw-xdht',
            subheadline:
              'Das Universalwerkzeug zum Schlichten und Konturfräsen mit kleinen Radien',
            vimeoId: '901813779',
            processingTypes: [
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'eintauchen-vertikal',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
              'kopierfraesen',
              'fasenfraesen',
            ],
            introText:
              'Die Rhombusfräser XDHW und XDHT zeichnen sich durch ausgezeichnete Laufruhe auch bei schwierigeren Eingriffsverhältnissen in Taschen und Eckbereichen aus. Die Fräser verfügen über eine geringe Leistungsaufnahme und bieten mit Einstellwinkeln von 93° und 95° höchste Präzision. Zudem sind Wendeschneidplatten mit Radien r = 1 mm und r = 2 mm verfügbar.',
            productImage: '/img/elements/tools/xdhw-xdht-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>6 66 391 + WSP: 04 79 835',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Vibrationsarmer Lauf durch den Einstellwinkel von 93° & 95°.',
                x: 21,
                y: 12,
                scale: 3,
                translateX: 42,
                translateY: 56,
              },
              {
                text: '5° Positiver Plattensitz, dadurch besonders sanfter Schnitt.',
                x: 64.5,
                y: 20,
                scale: 4,
                translateX: -43,
                translateY: 55,
              },
            ],
            featureList: [
              'Besonders ruhiger Werkzeuglauf in Ecken und Taschen',
              'Geringe Leistungsaufnahme',
              'Einstellwinkel κ = 93° (Größe 06), κ = 95° (Größe 10)',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'stainless-steel',
                main: false,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Fräser für die NE-Bearbeitung',
        name: 'fraeser-ne-bearbeitung',
        productList: [
          // vdgt
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Fräser für die NE-Bearbeitung',
            productName: 'VDGT',
            slug: 'vdgt',
            subheadline:
              'Der Schrupp- und Vorschlichtspezialist an senkrechten Wänden',
            vimeoId: '901813779',
            processingTypes: [
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
              'kopierfraesen',
            ],
            introText:
              'Der VDGT ermöglicht effiziente Schruppbearbeitungen von NE-Metallen und Nichtmetallen mit maximalen Zeitspanvolumen. Die Träger sind wahlweise ausgelegt mit Einstellwinkeln von κ = 90° oder κ = 93°, es ist also auch eine Bearbeitung von senkrechten Wänden oder Formschrägen möglich.',
            productImage: '/img/elements/tools/vdgt-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>25 261-3 SG + WSP: 02 11 820 (Platte silber)',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'κ = 90° und κ = 93° erhältlich.',
                x: 30,
                y: 14,
                scale: 4,
                translateX: 40,
                translateY: 58,
              },
              {
                text: '7° Positiv, für besonders ruhigen Schnitt.',
                x: 59,
                y: 16,
                scale: 3,
                translateX: -21,
                translateY: 51,
              },
            ],
            featureList: [
              'Ideal für die Zerspanung von Aluminium, Kupfer, Kunststoff und Grafit',
              'Besonders weicher Schnitt',
              'Einstellwinkel κ = 90° und κ = 93°',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
            ],
          },
          // vcgt
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Fräser für die NE-Bearbeitung',
            productName: 'VCGT',
            slug: 'vcgt',
            subheadline:
              'Der Schruppspezialist für hohe Schnittgeschwindigkeiten',
            vimeoId: '901813779',
            processingTypes: [
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
              'kopierfraesen',
            ],
            introText:
              'Der VCGT ermöglicht effiziente Schruppbearbeitungen von NE-Metallen und Nichtmetallen mit maximalen Zeitspanvolumen. Die Träger sind wahlweise ausgelegt mit Einstellwinkeln von κ = 90° oder κ = 92°, es ist also auch eine Bearbeitung von senkrechten Wänden oder Formschrägen möglich.',
            productImage: '/img/elements/tools/vcgt-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>100 360 + WSP: 05 22 820 (Platte Silber)',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'κ = 90° und κ = 92° erhältlich.',
                x: 11,
                y: 20,
                scale: 3,
                translateX: 64,
                translateY: 42,
              },
              {
                text: '3° positiv eingestellt, mit hoch positiver Schneide für besonders weichen Schnitt mit hohen Abtragsraten.',
                x: 72,
                y: 30,
                scale: 4,
                translateX: -58,
                translateY: 46,
              },
            ],
            featureList: [
              'κ = 90°und κ = 92° zur Bearbeitung von Kavitäten ohne Formschräge',
              'Besonders weicher Schnitt',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
            ],
          },
        ],
      },
      {
        title: 'Kugel- / Torusfräser',
        name: 'kugel-torusfraeser',
        productList: [
          // uniworx
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Kugel- / Torusfräser',
            productName: 'Uniworx®',
            slug: 'uniworx',
            subheadline:
              'Universalfräser mit maximalen Variationen für die Feinzerspanung',
            vimeoId: '901813779',
            processingTypes: ['kopierfraesen'],
            introText:
              'Uniworx® Wendeplattenfrässystem mit Kugelplatten oder Torusplatten für die variable Bearbeitung in der Feinzerspanung. Die Wendeschneidplatte ist über einen V-förmigen Plattensitz form- und kraftschlüssig mit dem Träger und der Passschraube verbunden. Zusätzlich kann flexibel zwischen der Kugelplatte und einer Torusplatte gewechselt werden.',
            productImage: '/img/elements/tools/uniworx-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>12 214 M6 + 12 835 V',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Platte möglich als Torus- und Kugelplatte',
                x: 44,
                y: 6,
                scale: 4,
                translateX: -8,
                translateY: 93,
              },
            ],
            featureList: [
              'Für Kugel- und Torusplatten',
              'V-Förmiger Plattensitz für form- und kraftschlüssige Verbindung von Werkzeugträger, Platte und Pass-Schraube',
              'Einfache Positionierung der Platten',
              'In einer Aufspannung gefertigter Schliff der Wendeschneidplatte mit wendelförmiger Spannute sorgt für ein weiches Schnittverhalten und beste Oberflächengüten',
              'Verschiedene Beschichtungen und Schneidstoffe, speziell auf die Feinzerspanung abgestimmt',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'stainless-steel',
                main: false,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: false,
                attributeList: ['Aluminium', 'Kupfer'],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
          // waveworx
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Kugel- / Torusfräser',
            productName: 'Waveworx®',
            slug: 'waveworx',
            subheadline: 'Exzellente Schruppbearbeitung in Stahl',
            vimeoId: '901813779',
            processingTypes: ['kopierfraesen'],
            introText:
              'Der Waveworx® ermöglicht eine effiziente Schruppbearbeitung speziell bei Aufgaben im Werkzeug- und Formenbau. Dies wird durch das Werkzeugkonzept mit zwei Schneiden und die Einstellung der Platte erreicht, die einen weichen Schnitt garantiert.',
            productImage: '/img/elements/tools/waveworx-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>32 275 + WSP: 06 32 850',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: '5° positiv eingestellt, dadurch besonders weicher Schnitt.',
                x: 32,
                y: 20,
                scale: 3,
                translateX: 26,
                translateY: 42,
              },
              {
                text: 'Eine Schneide über Mitte.',
                x: 49,
                y: 2,
                scale: 4,
                translateX: -8,
                translateY: 93,
              },
            ],
            featureList: [
              'Schruppen, Restmaterialbearbeitung und Vorschlichten in Stahl',
              'Auch bei großen Arbeitstiefen und niedrigen Drehzahlen einsetzbar',
              'Extrem stabiler Grundkörper',
              'Zwei Schneiden',
              'Eine Schneide über Mitte',
              'Weicher Schnitt',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Eckradius-/ Hoch-Vorschub-Fräser',
        name: 'eckradius-hoch-vorschub-fraeser',
        productList: [
          // uniworx plus
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Eckradius-/ Hoch-Vorschub-Fräser',
            productName: 'Uniworx® Plus',
            slug: 'uniworx-plus',
            subheadline:
              'Universalfräser mit maximalen Variationen für die Feinzerspanung',
            vimeoId: '901813779',
            processingTypes: [
              'kopierfraesen',
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
            ],
            introText:
              'Der Uniworx® Plus ermöglicht den Einsatz von Eckradius- sowie Hochvorschubplatten. So kann das Werkzeugsystem hoch wirtschaftlich und flexibel im Bereich der Schrupp- und Schlichtbearbeitung eingesetzt werden.  Hierfür ist kein Wechsel des Fräskörpers nötig. Die Wendeschneidplatten verfügen über eine einzigartige Kühlmittelzufuhr durch Fächeröffnungen, die eine effiziente Kühlung direkt in der Wirkzone gewährleistet.',
            productImage: '/img/elements/tools/uniworx-plus-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>16 215 SG + 15 16 8060 HF',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Mit Hochvorschubplatte oder Eckradiusplatte verfügbar',
                x: 48,
                y: 6,
                scale: 4,
                translateX: -8,
                translateY: 93,
              },
            ],
            featureList: [
              'Werkzeugkonzept für die Schrupp- und Schlichtbearbeitung in Stahl',
              'Schruppen- und Restmaterialbearbeitung mit High-Feed Wendeplatten',
              'Präzisionsgeschliffene Eckradiusplatten für die hochgenaue Fertigbearbeitung',
              'Asymmetrische Anlagenflächen zur fehlerfreien Positionierung',
              'Einzigartige Kühlmittelzufuhr mit Fächeröffnung durch die Wendeplatte',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: false,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: false,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
          {
            title: 'Quadworx',
            name: 'quadworx',
          },
          // slotworx hp s/m
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Eckradius-/ Hoch-Vorschub-Fräser', // Hochvorschubfräser / Eck- und Nutfräser
            productName: 'Slotworx® HP-Serie',
            slug: 'slotworx-hp-s-m',
            subheadline:
              'High Performance bei Zerspanungsleistung in der Hartbearbeitung',
            vimeoId: '901813779',
            processingTypes: [
              'kopierfraesen',
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
            ],
            introText:
              'Die Slotworx® HP-Serie ist das High-Performance-Werkzeugkonzept für eine Vielzahl von Anwendungsfällen. Es steht eine breite Palette an verschiedenen Hartmetallsubstraten, Beschichtungen und Geometrien sowohl für die Bearbeitung von weichen als auch gehärteten Materialien als auch anderen metallischen und nichtmetallischen Werkstoffen zur Verfügung. Zusätzlich kann der Slotworx® HP S durch Austauschen der Wendeschneidplatten vom Hochvorschubfräser zum Eck- und Nutfräser umgebaut werden.',
            productImage: '/img/elements/tools/slotworx-hp-s-m-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>S: 5 25 266 SG + 02 66 835 R20<br>M: HP06-042-A16-07 + HP06-8058-HF-RP',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Eingebetteter Plattensitz, für besonders stabile WSP.',
                x: 37,
                y: 21,
                scale: 3,
                translateX: 28,
                translateY: 46,
              },
              {
                text: 'Radius r = 0,8 mm für Eck- und Nutbearbeitungen, r = 2 mm für Hochvorschubarbeiten, mit definiertem Radius.',
                x: 66,
                y: 9,
                scale: 4,
                translateX: -42,
                translateY: 78,
              },
            ],
            featureList: [
              'Realer Eckradius für konturnahes Arbeiten (Slotworx HP S)',
              'Hochpräzise geschliffene Wendeschneidplatten',
              'Maximale Trägerstabilität durch negativen Axialwinkel',
              'Hohe Zähnezahl auf kleinstem Werkzeugdurchmesser',
              'Kann in Teilbereichen Vollhartmetallwerkzeuge ersetzen (Slotworx HP S)',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'ne-metalle',
                main: true,
                attributeList: ['Aluminium', 'Kupfer', 'Grafit', 'Kunststoffe'],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
          // fourworx
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Eckradius-/ Hoch-Vorschub-Fräser', // Hochvorschubfräser
            productName: 'Fourworx®',
            slug: 'fourworx',
            subheadline:
              'Mit mehr Leistung bei Geschwindigkeit, Vorschub und Schnitttiefe',
            vimeoId: '901813779',
            processingTypes: [
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
            ],
            introText:
              'Fourworx® ist ein Hochvorschubfräser für vielseitige Anwendungen. Hervorzuheben ist der negative Plattensitz für eine hohe Stabilität sowie die vier nutzbaren Schneidkanten pro Wendeschneidplatte. Die speziell entwickelte Wendeschneidplattengeometrie sorgt für einen sehr weichen Schnitt auch bei Einsätzen in tiefen Kavitäten und labilen Bauteilen.',
            productImage: '/img/elements/tools/fourworx-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>FR05-032-E16-05 + FR05-8096-HF-MM',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Negativer Plattensitz, für besonders hohe Stabilität, sehr weicher Schnitt',
                x: 50,
                y: 16,
                scale: 3.5,
                translateX: -7,
                translateY: 62,
              },
              {
                text: 'Vier Schneidkanten je WSP für höchste Wirtschaftlichkeit',
                x: 66,
                y: 7,
                scale: 4,
                translateX: -42,
                translateY: 92,
              },
              {
                text: 'Gefaste Außenkanten schützen die Platten vor Abplatzungen am Umfang',
                x: 37,
                y: 5,
                scale: 4.25,
                translateX: 41,
                translateY: 92,
              },
              {
                text: 'Neuartiger Verstärkungskragen um den Schraubensitz für außerordentliche Standzeiten',
                x: 32.25,
                y: 13,
                scale: 3.75,
                translateX: 31,
                translateY: 74,
              },
            ],
            featureList: [
              'Höchste Zähnezahl auf kleinstem Durchmesser',
              'Vier Schneidkanten je Platte',
              'Ideal für labile Bauteile und in tiefen Kavitäten durch sehr weichen Schnitt',
              '3D-Spanmulde für beste Spanabfuhr',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: false,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: false,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
          // quadworx m/l/xl
          {
            productLine: 'Wendeplatten-Werkzeuge',
            productGroup: 'Eckradius-/ Hoch-Vorschub-Fräser', // Hochvorschubfräser
            productName: 'Quadworx®',
            slug: 'quadworx',
            subheadline:
              'Vorschub zum Quadrat – höchste Wirtschaftlichkeit für universellen Einsatz',
            vimeoId: '901813779',
            processingTypes: [
              'planfraesen',
              'eintauchen-mit-rampe',
              'eintauchen-zirkular',
              'nutfraesen',
              'eckfraesen',
              'taschenfraesen',
            ],
            introText:
              'Das Quadworx®-Werkzeugkonzept deckt mit seiner Vielzahl an Wendeschneidplattengrößen, -substraten und -beschichtungen ein sehr breites Bearbeitungsspektrum im Bereich der High-Feed-Werkzeuge ab. Der Quadworx® XL ermöglicht durch speziell konstruierte Wendeschneidplatten zusätzlich eine einfache Umrüstung vom High-Feed-Fräser zum Eck- und Nutfräser.',
            productImage: '/img/elements/tools/quadworx-xl-hires.webp',
            highlightList: [
              {
                text: 'Selbstdrehende Wendeschneidplatte – Intro.<br>M: 3 25 248 SG + 03 48 846<br>XL: 6 66 351 RF + 05 51 848 HF',
                x: 0,
                y: 0,
                scale: 1,
                translateX: 0,
                translateY: 0,
              },
              {
                text: 'Positiver Plattensitz, für besonders weichen Schnitt.',
                x: 62,
                y: 18,
                scale: 3,
                translateX: -26,
                translateY: 44,
              },
              {
                text: 'Eingebetteter Plattensitz, für höchste Stabilität und genaueste Positionierung der WSP.',
                x: 42,
                y: 22,
                scale: 4,
                translateX: 18,
                translateY: 69,
              },
            ],
            featureList: [
              'Universeller Einsatz im Bereich des High-Feed-Fräsens',
              'Sehr hohe Abtragsraten und sehr weicher Schnitt',
              'Vier Schneiden je Schneidplatte für hoch wirtschaftlichen Einsatz',
              'Durch Positionierung der Schneidplatte über eine zweite Freifläche und 90°-Anlage wird ein Verdrehen der Platte ausgeschlossen',
              'Maximale Prozesssicherheit im unterbrochenen Schnitt durch sichere Positionierung der Schneidplatte',
              'Mit Planschneide und Großradius werden schon in der Schruppbearbeitung sehr gute Oberflächen erzeugt',
              'Spezielle Legierung für hohe Langlebigkeit und Korrosionsbeständigkeit, zusätzlich aus über 80% recyceltem Stahl für minimale Umweltbelastung',
            ],
            materialList: [
              {
                slug: 'steel',
                main: true,
                attributeList: [
                  'unlegierter Stahl Baustahl',
                  'normale Werkzeugstähle / Stahlguss',
                  'Werkzeugstähle, schwer zerspanbar / Stahlguss, schwer zerspanbar',
                ],
              },
              {
                slug: 'hochwarmfeste-legierung',
                main: true,
                attributeList: ['Warmfeste Legierungen', 'Titanlegierungen'],
              },
              {
                slug: 'stainless-steel',
                main: true,
                attributeList: ['Alle Sorten'],
              },
              {
                slug: 'gusseisen',
                main: true,
                attributeList: [
                  'Grauguss',
                  'Kugelgrafitguss',
                  'Vergüteter Guss',
                ],
              },
              {
                slug: 'gehaertete-werkstoffe',
                main: true,
                attributeList: [
                  'bis 48 HRC',
                  'bis 52 HRC',
                  'bis 55 HRC',
                  'bis 62 HRC',
                ],
              },
            ],
          },
          {
            title: 'Slotworx HF (Kappa 15°)',
            name: 'slotworx-hf-kappa-15',
          },
        ],
      },
    ],
  },
  {
    title: 'Wendeschneidplatten',
    name: 'turning-inserts',
    teaser:
      'Mit unseren Wendeschneidplatten schneiden und fräsen Sie effizient und präzise.',
    text: 'config.sectors.toolmaking.text',
    route: '#',
    image: {
      source: '/img/elements/tools/placeholder-turning-inserts.webp',
      alt: '',
    },
    productGroup: [
      {
        title: '???',
        name: '#',
        productList: [
          {
            title: '???',
            name: '#',
          },
        ],
      },
    ],
  },
  {
    title: 'Aufnahmesysteme',
    name: 'recording-systems',
    teaser:
      'Unsere Aufnahmesysteme und Schrumpfgeräte bieten Ihnen höchste Präzision und Zuverlässigkeit bei der Fertigung.',
    text: 'config.sectors.toolmaking.text',
    route: '#',
    image: {
      source: '/img/elements/tools/placeholder-recording-systems.webp',
      alt: '',
    },
    // productGroup = productList
    productList: [
      {
        title: 'Hohlschaftkegel HSK',
        name: 'hohlschaftkegel-hsk',
      },
      {
        title: 'Steilkegel SK | BT',
        name: 'steilkegel-sk-bt',
      },
      {
        title: 'Plananlagen',
        name: 'plananlagen',
      },
      {
        title: 'Stangen | Verlängerungen',
        name: 'stangen-verlaengerungen',
      },
    ],
  },
  {
    title: 'Vollhartmetall-Werkzeuge',
    name: 'carbide-tools',
    teaser:
      'Mit unseren Vollhartmetall-Werkzeugen erreichen Sie eine hohe Langlebigkeit und Effizienz bei der Fertigung.',
    text: 'config.sectors.toolmaking.text',
    route: '#',
    image: {
      source: '/img/elements/tools/placeholder-carbide-tools.webp',
      alt: '',
    },
    //productGroups as single pages, with spectrum of products shown on
    productGroup: [
      {
        title: 'Schaftfräser (Schutzfasen/Schutzradien angedeutet)',
        name: 'schaftfraeser-schutzfasen-schutzradien-angedeutet',
      },
      {
        title: 'Torus-/ Eckradiusfräser',
        name: 'torus-eckradiusfraeser',
      },
      {
        title: 'Vollradiusfräser',
        name: 'vollradiusfraeser',
      },
      {
        title: 'HPC / HSC',
        name: 'hpc-hsc',
      },
      {
        title: 'Hochvorschubfräser',
        name: 'hochvorschubfraeser',
      },
      {
        title: 'Trochoidialfräser',
        name: 'trochoidialfraeser',
      },
      {
        title: 'Bogensegmentfräser',
        name: 'bogensegmentfraeser',
      },
    ],
  },
  {
    title: 'Spindelsysteme',
    name: 'spindle-systems',
    teaser:
      'Mit unseren Spindelsystemen erreichen Sie höchste Präzision und Qualität in der Fertigung.',
    text: 'config.sectors.toolmaking.text',
    route: '#',
    image: {
      source: '/img/elements/tools/placeholder-spindle-systems.webp',
      alt: '',
    },
    // productGroup = productList
    productList: [
      {
        title: '50.000 Umdrehung',
        name: '50000-umdrehung',
      },
      {
        title: '80.000 Umdrehung',
        name: '80000-umdrehung',
      },
    ],
  },

  {
    title: 'Schrumpfgeräte',
    name: 'shrink-fit-machines',
    teaser:
      'Unsere Aufnahmesysteme und Schrumpfgeräte bieten Ihnen höchste Präzision und Zuverlässigkeit bei der Fertigung.',
    text: 'config.sectors.toolmaking.text',
    route: '#',
    image: {
      source: '/img/elements/tools/placeholder-recording-systems.webp',
      alt: '',
    },
    // productGroup = productList
    productList: [
      {
        title: 'Kleines Schrumpfgerät',
        name: 'kleines-schrumpfgeraet',
      },
      {
        title: 'Großes Schrumpfgerät',
        name: 'grosses-schrumpfgeraet',
      },
    ],
  },
  {
    title: 'Graviersysteme (Gravfix®)',
    name: 'engraving-systems',
    teaser:
      'Bringen Sie mit unseren Graviersystemen feinste Details und Muster auf Ihre Produkte.',
    text: 'config.sectors.toolmaking.text',
    route: '#',
    image: {
      source: '/img/elements/tools/placeholder-engraving-systems.webp',
      alt: '',
    },
    // productGroup = productList
    productList: [
      {
        title: 'Graviersystem Gravfix',
        name: 'graviersystem-gravfix',
      },
    ],
  },
  {
    title: 'Oberflächenveredelung (Forgefix®)',
    name: 'surface-finishing',
    teaser:
      'Unsere Oberflächen&shy;veredelungs&shy;technologien sorgen für Ästhetik und Haltbarkeit Ihrer Produkte.',
    text: 'config.sectors.toolmaking.text',
    route: '#',
    image: {
      source: '/img/elements/tools/placeholder-surface-finishing.webp',
      alt: '',
    },
    // productGroup = productList
    productList: [
      {
        title: 'Oberflächenveredelung Forgefix',
        name: 'oberflaechenveredelung-forgefix',
      },
    ],
  },
]

export default toolList
